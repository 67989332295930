<template>
  <div>
    <div v-if="localPage === 'list'">
      <RadioList :content="content" :item="item" @openEdit="openEdit"/>
    </div>
    <div v-if="localPage === 'edit'">
      <RadioEdit :content="content" :item="item" @openList="openList"/>
    </div>
    <div v-if="localPage === 'style'">
      <RadioEdit :content="content" :item="item" @openList="openList"/>
    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "typeRadio",
  components: {
    RadioEdit: () => import("@/components/Constructor/Menu/Radio/RadioEdit"),
    RadioList: () => import("@/components/Constructor/Menu/Radio/RadioList"),
  },
  props: ['item', 'content'],
  data() {
    return {
      localPage: "list",
    }
  },
  mounted(){
    this.setStationsData(this.content.content);
  },
  methods: {
    ...mapMutations('radio', ['setSelectedStation','setStationsData']),
    openEdit() {
      this.localPage = 'edit';
    },
    openList() {
      this.localPage = 'list';
      const payload = {
        selectedGroupID: null,
        selectedRadio: null
      }
      this.setSelectedStation(payload);
    }
  }
}
</script>

<style scoped>

</style>